<template>
  <div class="orderLogistics">
    <van-cell-group v-if="allOrderNumber">
      <van-cell
        class="step-cell"
        :title="$t.orderDetails.orderNumber"
        :value="allOrderNumber"
      />
    </van-cell-group>

    <van-cell-group v-if="postNumber">
      <van-cell
        class="step-cell"
        :title="$t.orderDetails.logisticsNumber"
        :value="postNumber"
      />
    </van-cell-group>

    <van-steps direction="vertical" :active="0">
      <van-step v-for="item in orderLogistics" :key="item.CreateTimeTS">
        <h3>{{ item.LStateStr }}</h3>
        <p>{{ item.CreateTime }}</p>
      </van-step>
    </van-steps>
  </div>
</template>
<script>
import Vue from "vue";
import { Step, Steps, Cell, CellGroup } from "vant";
Vue.use(Step).use(Steps).use(Cell).use(CellGroup);
export default {
  name: "OrderLogistics",
  components: {},
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      orderLogistics: [],
      allOrderNumber: "",
      postNumber: "",
    };
  },
  mounted() {
    const { AllOrderNumber, PostNumber } = this.$route.query;
    this.allOrderNumber = AllOrderNumber;
    this.postNumber = PostNumber;
    this.loadOrderLogistics();
  },
  methods: {
    // 获取搜索页数据
    loadOrderLogistics() {
      this.$commonMethod.showLoading();
      let param = {
        OrderNumber: this.id,
      };
      this.$api.order
        .loadOrderLogistics(param)
        .then((res) => {
          console.log(res);
          this.orderLogistics = res.data;
          this.$commonMethod.hideLoading();
        })
        .catch((error) => {
          // this.isfirst = false
          // console.log(error);
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
      // console.log("这是isfirst" + this.isfirst);
    },
  },
};
</script>
<style lang="scss" scoped>
.orderLogistics {
  width: 100%;
  p {
    font-size: 13px;
  }
  h3 {
    margin: 0;
    font-size: 15px;
  }
  .van-steps {
    padding: 10px 0 0 32px;
  }
}
.van-hairline--top-bottom::after {
  border-width: 0px !important;
}
</style>
